body {
  background-image: url("./images/background.png");
  background-attachment: fixed;
}

.body-mask {
  background-color: white;
  opacity: 0.95;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
}

.App {
  position:relative;
  z-index: 2;
  font-size: calc(10px + 2vmin);
  color: #42446d;
}

.nav-link {
  text-align: center;
}